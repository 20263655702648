import { DoubleArrow, Inventory2Outlined, TrafficOutlined, WifiTethering } from "@mui/icons-material";
import MapIcon from "@mui/icons-material/Map";
import Add from "@mui/icons-material/Add";

const getFloorTypeConfig = (type) => {
    const floorTypes = {
        'heatmap': {
            title: 'LP Heat Map',
            icon: () => <MapIcon sx={{ pr: 1, color: "#696B6D" }} />
        },
        'lp2bin': {
            title: 'LP2Bin (Full)',
            icon: () => <Inventory2Outlined sx={{ pr: 1, color: "#696B6D" }} />
        },
        'sequence': {
            title: 'Sequence',
            icon: () => <DoubleArrow sx={{ pr: 1, color: "#696B6D" }} />
        },
        'rpi': {
            title: 'RPI',
            icon: () => <WifiTethering sx={{ pr: 1, color: "#696B6D" }} />
        },
        'congestion': {
            title: 'Congestion Management',
            icon: () => <TrafficOutlined sx={{ pr: 1, color: "#696B6D" }} />
        },
        'lp2binUpdate': {
            title: 'LP2Bin (Update)',
            icon: () => <Add sx={{ pr: 1, color: "#696B6D" }} />
        },

    }
    return floorTypes[type] || {}
}
export default getFloorTypeConfig