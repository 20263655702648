import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1ab394',
            contrastText: "#fff"
        },
        background: {
            paper: '#fff',
        },
        text: {
            error: 'red',
        },
        secondary: {
            main: '#1ab394',
        },
        blue: {
            main: "#4182C1",
            contrastText: "#fff"
        },
        lightGrey: {
            main: '#EEEEEE'
        }
    }
});

export default theme;