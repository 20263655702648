import React from 'react'
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoIcon from '@mui/icons-material/Info';
import { Stack } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";

function Info() {
    return (
        <Grid container sx={{ pr: 3, pl: 3, }}>
            <Grid item md={12}>
                <Card
                    sx={{ minWidth: "100%" }}
                    style={{ backgroundColor: "rgba(83, 176, 149, 0.05)" }}
                    variant="outlined">

                    <Stack direction="row" alignItems="center" columnGap={1} sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
                        <InfoIcon sx={{ fontSize: 22, fill: "#4182C1" }} />
                        <Typography sx={{ fontSize: 18, mt: 1 }}
                            color="#53B095"
                            gutterBottom ><b>{`Please select a client and warehouse, then click "Confirm" to begin`}</b></Typography>
                    </Stack>
                </Card>
            </Grid>
        </Grid>

    )
}


export default Info