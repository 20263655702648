import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
const buttonSx = {
    fontSize: 16,
    fontWeight: 700,
    padding: 0,
    minWidth: "auto",
};
const Pagination = ({ total, totalPage, pageSize, currentPage, onPageChange }) => {
    return (
        <Stack
            flexDirection="row"
            columnGap={2}
            sx={{
                mt: 1,
            }}
        >
            <Button
                disabled={currentPage === 1}
                sx={buttonSx}
                onClick={() => {
                    onPageChange(currentPage - 1);
                }}
            >
                <KeyboardArrowLeftIcon />
            </Button>
            {[...Array(totalPage)]?.map((item, index) => {
                const page = index + 1;
                return (
                    <Button
                        onClick={() => {
                            onPageChange(page);
                        }}
                        key={page}
                        sx={
                            page === currentPage
                                ? { ...buttonSx, color: "#696B6D" }
                                : buttonSx
                        }
                        size="large"
                    >
                        {page}
                    </Button>
                );
            })}
            <Button
                sx={buttonSx}
                disabled={currentPage === totalPage}
                onClick={() => {
                    onPageChange(currentPage + 1);
                }}
            >
                <KeyboardArrowRightIcon />
            </Button>
        </Stack>
    );
};

export default Pagination;
