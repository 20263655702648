import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import EditIcon from '@mui/icons-material/Edit';
import FormLabel from '@mui/material/FormLabel'
import { LoadingButton } from '@mui/lab';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";

const FloorForm = ({
    floorItem,
    handleFloorInfoConfirmation,
    floorsUpdate,
    setFloorsInfo,
    i,
    floorSubmitLoading,
    floorsInfo
}) => {
    const [isFloorLabelDuplicate, setIsFloorLabelDuplicate] = useState(false);
    return (
        <>
            {
                !floorItem?.isEdit ? (
                    <Stack onClick={(event) => {
                        event.stopPropagation();
                    }}>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            handleFloorInfoConfirmation(i)
                        }}>
                            <Stack direction={{ sm: 'column', md: 'row' }} alignItems={{ sm: 'flex-start', md: 'center' }} gap={3}>
                                <Stack direction={{ sm: 'column', md: 'row', }} alignItems={{ sm: 'flex-start', md: 'center' }} gap={2}>
                                    <FormLabel>Floor Label:</FormLabel>
                                    <TextField
                                        name="description"
                                        required
                                        error={isFloorLabelDuplicate}
                                        size="small"
                                        defaultValue={floorItem.description}
                                        onChange={(e) => {
                                            const floorsLabelExists = floorsInfo.find(floor => (floor.description === e.target.value))
                                            if (!!floorsLabelExists) {
                                                setIsFloorLabelDuplicate(true)
                                            } else {
                                                setFloorsInfo(oldFloorsInfo => {
                                                    setIsFloorLabelDuplicate(false)
                                                    return floorsUpdate(oldFloorsInfo, { description: e.target.value }, i)
                                                })
                                            }
                                        }}
                                    />
                                </Stack>
                                <LoadingButton
                                    sx={{
                                        fontWeight: 600, width: 'auto', boxShadow: 0, "&:hover": {
                                            boxShadow: 0
                                        }
                                    }}
                                    disableElevation
                                    disableRipple
                                    disabled ={isFloorLabelDuplicate}
                                    color="primary"
                                    variant="contained"
                                    loading={floorSubmitLoading}
                                    type="submit"
                                >
                                    Save
                                </LoadingButton>
                                {isFloorLabelDuplicate && <Typography color="red" fontSize={12}>Floor Label already exists!</Typography>}
                            </Stack>
                        </form>
                    </Stack>
                ) : (
                    <Stack flexDirection="row" alignItems="center" onClick={(event) => {
                        event.stopPropagation();
                    }}>
                        <Typography
                            sx={{
                                flexShrink: 0,
                                fontSize: 18,
                                fontWeight: 600,
                                cursor: "default",
                                display: "flex",
                                alignItems: "center",
                                color: '#696A6B'
                            }}
                        >
                            {floorItem.description}{" "}
                            <Tooltip title="Edit Floor Label">
                                <EditIcon
                                    sx={{
                                        fontSize: 16,
                                        marginLeft: "8px",

                                        "&:hover": {
                                            color: "green",
                                            cursor: "pointer",
                                        }
                                    }}
                                    color="blue"
                                    onClick={() => {
                                        setFloorsInfo(oldFloorsInfo => {
                                            return floorsUpdate(oldFloorsInfo, { isEdit: false }, i)
                                        })
                                    }}
                                />
                            </Tooltip>
                        </Typography>
                    </Stack>
                )
            }
        </>
    )
}



export default FloorForm