import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DownloadIcon from '@mui/icons-material/Download';

function ErrorSummary({
    validateData,
    downloadURI
}) {

    return (
        <Grid xs={12} sx={{ mb: 2 }}>
            <Card
                sx={{ minWidth: "100%", pb: 2, pt: 1 }}
                style={{ backgroundColor: "#fbe5dc" }}
                variant="outlined"
            >
                <Grid xs={12} sx={{ mb: 1 }}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <Typography
                            sx={{ fontSize: 18, fontWeight: 600, marginBottom: 0 }}
                            color="error"
                            gutterBottom
                        >
                            Validation Summary - {validateData.summary.errorsCount} Error{validateData.summary.errorsCount > 1 && 's'}
                        </Typography>
                        <Button
                            className="ml-auto"
                            color="error"
                            variant="text"
                            size="large"
                            onClick={() =>
                                downloadURI(validateData.reportToken.combinedUrl)
                            }
                        >
                            <DownloadIcon />
                            Download All Errors
                        </Button>
                    </div>
                    <Stack direction="row" alignItems='center' sx={{ columnGap: 2 }}>
                        {
                            validateData?.summary?.uiSummary?.filter(error => error?.text === 'Total LPs:' || error?.text === 'Total Locations:')?.map((error, index) => (
                                <Stack key={error?.text} direction="row" alignItems='center' sx={{ columnGap: 2 }}>
                                    {
                                        index === 1 && (
                                            <Typography color='error' fontSize={14} fontWeight={600} variant="p">|</Typography>
                                        )
                                    }
                                    <Stack key={index}>

                                        <Typography color='error' fontSize={14} fontWeight={600} variant="p">{`${error.text} ${error.value}`}</Typography>

                                    </Stack>
                                </Stack>
                            ))
                        }

                    </Stack>
                </Grid>
                <Stack
                    sx={{ rowGap: 1, columnGap: 2, rowGap: 1, padding: '0px 15px', flexFlow: 'wrap' }}
                    direction="row"
                    alignItems="top"

                >
                    {
                        validateData?.summary?.uiSummary?.filter(error => !!error.visible && error?.text !== 'Total LPs:' && error?.text !== 'Total Locations:')?.map((error, index) => (
                            <Stack key={index}>
                                <Typography color='error' fontSize={14} variant="p">{`${error.text} ${error.value}`} </Typography>
                            </Stack>
                        ))
                    }
                </Stack>
            </Card>
        </Grid>
    )
}


export default ErrorSummary