import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const RootContext = createContext();

function RootProvider({ children }) {
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [message, setMessage] = useState({});
    const [domain, setDomain] = useState(null)
    const [locusWarehouse, setLocusWarehouse] = useState(null)
    const [disabledConfirm, setDisabledConfirm] = useState(false);
    const contextValue = {
        clients,
        setClients,
        client,
        setClient,
        warehouse,
        setWarehouse,
        warehouses,
        setWarehouses,
        message,
        setMessage,
        disabledConfirm,
        setDisabledConfirm,
        setDomain,
        domain,
        setLocusWarehouse,
        locusWarehouse
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setMessage({});
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <RootContext.Provider value={contextValue}>
            {message?.text && (
                <Snackbar
                    open={!!message?.text}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={message.type}
                        sx={{ width: "100%" }}
                    >
                        {message.text}
                    </Alert>
                </Snackbar>
            )}
            {children}
        </RootContext.Provider>
    );
}

export default RootProvider;

export const useRoot = () => {
    return useContext(RootContext);
};
