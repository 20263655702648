export function getClients(parameters) {
    const { headers, params } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_PORTAL_API}/api/Client/GetClientLookupValues`,
        params,
        headers
    }
}
export function getWarehousesAccordingToClientId(parameters) {
    const { headers, params } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_PORTAL_API}/api/Client/GetClientWarehouseLookupValues`,
        params,
        headers
    }
}

export function getActiveWarehouseFiles(parameters) {
    const { headers, params } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/active`,
        params,
        headers
    }
}

export function getWarehouseConfig(parameters) {
    const { headers, params } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_SERVICES_API}/services/site/find`,
        params,
        headers
    }
}

export function getWarehouseSASForUpload(parameters) {
    const { params, headers } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/access/update`,
        params,
        headers,
    }
}

export function uploadFile(parameters) {
    const { headers, url, data } = parameters
    return {
        method: 'PUT',
        url,
        data,
        headers
    }
}

export function removeUploadedFiles(parameters) {
    const { headers, data } = parameters;
    return {
        method: "delete",
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/uploads`,
        data,
        headers
    }
}

export function validateFiles(parameters) {
    const { data, headers } = parameters
    return {
        method: 'post',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/validate`,
        data,
        headers
    }
}

export function getValidStatus(parameters) {
    const { params, headers } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/status`,
        params,
        headers
    }
}


export function submitFiles(parameters) {
    const { data, headers } = parameters
    return {
        method: 'post',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/submit`,
        data,
        headers
    }
}
export function getFloors(parameters) {
    const { headers } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/floors`,
        headers
    }
}
export function createOrUpdateFloor(parameters) {
    const { data, headers } = parameters
    return {
        method: data.id ? 'put' : 'post',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/floor`,
        data,
        headers
    }
}

export function getLp2BinRows(parameters) {
    const { params, headers } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/lp2bin/rows`,
        params,
        headers
    }

}

export function exportCurrentView(parameters) {
    const { params, headers } = parameters
    return {
        method: 'get',
        url: `${process.env.REACT_APP_CONFIG_API_TOOLING}/warehouse/lp2bin/export`,
        params,
        headers
    }
}