import * as React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateLayout from './components/layouts/PrivateLayout';
import Welcome from './pages/welcome';
import SiteConfigView from './pages/welcome/view';
import PowerBi from './pages/powerBi';
import RootProvider from './context/RootProvider';
function App() {
  return (
    <RootProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateLayout />}>
            <Route path="/site-config/:userName/:userId/:accessToken" exact action="read" element={<Welcome />} />
            <Route path="/site-config/view/:floorId/:userName/:userId/:accessToken" exact action="read" element={<SiteConfigView />} />
            <Route path="/powerbi/:clientId/:warehouseId/:userName/:userId/:accessToken/:reportName/:reportResolution" exact subject="Dashboard" action="read" element={<PowerBi />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </RootProvider>
  )
}


export default App