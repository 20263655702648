import React from 'react';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip'
const dayjs = require('dayjs')

const FileInfo = ({ fileName, updatedAt, username, fileUploaded }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid #E7EAEC", m: 1, p: 1 }}>
            <Box sx={{ width: "100%" }}>
                {!!fileName && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{ pb: 1 }}
                    >
                        <Stack>
                            <Typography sx={{ fontSize: 14, color: "#696B6D" }}>
                                <b>File Name:</b>
                            </Typography>
                        </Stack>
                        <Stack><Typography sx={{ fontSize: 14, color: "#696B6D", wordBreak: 'break-word' }}>{fileName}</Typography></Stack>
                    </Stack>
                )}
                {!!updatedAt && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{ pb: 1 }}
                    >
                        <Stack>
                            <Typography sx={{ fontSize: 14, color: "#696B6D" }}>
                                <b>Added:</b>
                            </Typography>
                        </Stack>
                        <Stack><Typography sx={{ fontSize: 14, color: "#696B6D" }}>{dayjs(updatedAt).format('MM/DD/YYYY')}</Typography></Stack>
                    </Stack>
                )}
                {!!username && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{ pb: 1 }}
                    >
                        <Stack>
                            <Typography sx={{ fontSize: 14, color: "#696B6D" }}>
                                <b>Added By:</b>
                            </Typography>
                        </Stack>
                        <Stack><Typography sx={{ fontSize: 14, color: "#696B6D" }}>{username}</Typography></Stack>
                    </Stack>
                )}
            </Box>
            <Box>
                <Chip color={fileUploaded ? "success" : "info"} size="small" label={fileUploaded ? "New" : "Current"} />
            </Box>
        </Stack>
    )
}

export default FileInfo;