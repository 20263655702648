import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';

function SuccessSummary({
    submitSuccess,
    submitSuccessData,
    downloadURI,
    validateData
}) {
    return (
        <Grid xs={12} sx={{ mb: 2 }}>
            <Card
                sx={{ minWidth: "100%" }}
                style={{ backgroundColor: "rgba(83, 176, 149, 0.05)" }}
                variant="outlined"
            >
                <Grid xs={12}>
                    {
                        !submitSuccess && (
                            <div style={{ display: "flex" }}>
                                <Typography
                                    sx={{ fontSize: 18, mt: 1 }}
                                    color="#53B095"
                                    gutterBottom
                                >

                                    <b>Validation passed, please submit for implementation or add next floor.</b>
                                </Typography>


                                <Button
                                    className="ml-auto"
                                    color="primary"
                                    variant="text"
                                    size="large"
                                    onClick={() =>
                                        downloadURI(validateData.reportToken.combinedUrl)
                                    }
                                >
                                    <DownloadIcon />
                                    Download Results
                                </Button>
                            </div>
                        )
                    }
                    {submitSuccess && (
                        <>
                            <Typography>
                                <Link sx={{ textDecoration: 'none' }} href={submitSuccessData.issueUrl} target="_blank" rel="noopener" ><b>Submission Successful.</b></Link>
                            </Typography>
                            <Stack direction="row" alignItems="center" sx={{ columnGap: 0.5 }}>
                                <Typography color="primary" sx={{ fonSize: 14 }}>View Issue: </Typography>
                                <Link sx={{
                                    fontSize: 14,
                                    color: '#4182C1',
                                    textDecorationColor: '#4182C1',
                                    "&:hover": {
                                        color: '#696A6B',
                                        cursor: "pointer",
                                    }
                                }} href={submitSuccessData.issueUrl} target="_blank" rel="noopener"> {submitSuccessData.key}</Link>
                            </Stack>

                        </>
                    )}
                    {
                        !submitSuccess && (
                            <Stack
                                sx={{ rowGap: 1, columnGap: 2, rowGap: 1, flexFlow: 'wrap' }}
                                direction="row"
                                alignItems="top"

                            >
                                {
                                    validateData?.summary?.uiSummary?.filter(success => !!success.visible)?.map((success, index) => (
                                        <Stack key={index}>
                                            <Typography color='primary' fontSize={14} variant="p">{`${success.text} ${success.value}`}</Typography>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                        )
                    }
                </Grid>
            </Card>
        </Grid>
    )
}


export default SuccessSummary