import Grid from "@mui/material/Unstable_Grid2";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { useRoot } from "../../context/RootProvider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { makeRequest } from "../../utils";
import { useEffect, useState } from "react";
import { apiConfigs } from "../../services";
let locusWarehouse = null;
let domain = null;
let locusHeader = {};
const SearchSites = ({ handleClickConfirm }) => {
    const [clientLoading, setClientLoading] = useState(false);
    const [warehouseLoading, setWarehouseLoading] = useState(false);
    const {
        setClient,
        client,
        setClients,
        clients,
        setWarehouse,
        warehouse,
        setWarehouses,
        warehouses,
        setMessage,
        disabledConfirm,
    } = useRoot();
    let { userId, accessToken, userName } = useParams();
    const getClients = async () => {
        setClientLoading(true);
        setWarehouses([]);

        return makeRequest(
            apiConfigs.getClients({
                params: {
                    loggedInUserId: userId,
                    isDemo: "false",
                },
                headers: locusHeader,
            })
        )
            .then((response) => {
                return setClients(response?.Results || []);
            })
            .catch((err) => {
                console.log(err);
                setMessage({
                    type: "error",
                    text: "Request Could not be processed please try again",
                });
            })
            .finally((err) => setClientLoading(false));
    };

    const getWarehouses = async (clientId) => {
        setWarehouseLoading(true);
        setWarehouses([]);
        return makeRequest(
            apiConfigs.getWarehousesAccordingToClientId({
                params: {
                    loggedInUserId: userId,
                    clientId,
                    isDemo: "false",
                },
                headers: locusHeader,
            })
        )
            .then((response) => {
                return setWarehouses(response?.Results || []);
            })
            .catch((err) =>
                setMessage({
                    type: "error",
                    text: "Request Could not be processed please try again",
                }).finally((err) => setWarehouseLoading(false))
            );
    };

    useEffect(() => {
        locusHeader = {
            "locus-warehouse": locusWarehouse,
            "locus-client-id": "",
            "locus-user": `{"username":"${userName}"}`,
            "locus-domain": domain,
            authorization: `Bearer ${accessToken}`,
            "locus-region": process.env.REACT_APP_LOCUS_REGION,
        };
        if (!clients?.length) {
            getClients();
        }
    }, []);
    return (
        <Grid container direction="row" alignItems="center" xs={12} sx={{ p: 1 }}>
            <FormGroup row>
                <Stack direction="row" alignItems="center" gap={0} sx={{ p: 2 }}>
                    <AccountBalanceIcon fontSize="small" />
                    <FormControlLabel
                        label="Select Client"
                        labelPlacement="start"
                        control={
                            <Autocomplete
                                loading={clientLoading}
                                disablePortal
                                size="small"
                                variant="outlined"
                                id="combo-box-client"
                                options={clients}
                                sx={{ width: 200 }}
                                style={{ paddingLeft: "20px" }}
                                getOptionLabel={(option) => option.ClientName}
                                isOptionEqualToValue={(option, value) =>
                                    option.ClientId === value.ClientId
                                }
                                value={client}
                                onChange={(event, newValue) => {
                                    setClient(newValue);
                                    setWarehouse(null);
                                    newValue
                                        ? getWarehouses(newValue.ClientId)
                                        : setWarehouses([]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        label={!client ? "Select Client" : null}
                                        {...params}
                                        sx={{ fontSize: 12 }}
                                    />
                                )}
                            />
                        }
                    />
                </Stack>
                <Stack direction="row" alignItems="center" gap={0} sx={{ p: 2 }}>
                    <LocalShippingIcon fontSize="small" />
                    <FormControlLabel
                        label="Select Warehouse"
                        labelPlacement="start"
                        control={
                            <Autocomplete
                                loading={warehouseLoading}
                                disabled={!client}
                                disablePortal
                                size="small"
                                variant="outlined"
                                id="combo-box-warehouse"
                                options={warehouses}
                                sx={{ width: 200 }}
                                style={{ paddingLeft: "20px" }}
                                getOptionLabel={(option) => option.WarehouseFriendlyName}
                                isOptionEqualToValue={(option, value) =>
                                    option.WarehouseId === value.WarehouseId
                                }
                                value={warehouse}
                                onChange={(event, newValue) => {
                                    setWarehouse(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        label={!warehouse ? "Select Warehouse" : null}
                                        {...params}
                                        sx={{ fontSize: 12 }}
                                    />
                                )}
                            />
                        }
                    />
                </Stack>
            </FormGroup>
            <Box ml={2}>
                {client && warehouse && (
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={disabledConfirm}
                        sx={{
                            fontSize: 12,
                            minWidth: 130,
                            boxShadow: 0,
                            "&:hover": {
                                boxShadow: 0,
                            },
                        }}
                        onClick={handleClickConfirm}
                    >
                        Confirm
                    </Button>
                )}
            </Box>
        </Grid>
    );
};

export default SearchSites;
