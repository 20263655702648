import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { getFloorTypeConfig } from "../../../utils";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import { UploadFile } from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DropZoneArea from '../../../components/DropZoneArea';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import FileInfo from '../../../components/FileInfo';

function ActiveWarehouseFiles({
    activeWarehouseFiles,
    downloadURI,
    uploadFile,
    validateLoading,
    setMessage,
    navigateToViewFile
}) {
    const { userName } = useParams()
    return (
        <>
            {!!activeWarehouseFiles?.length && activeWarehouseFiles?.map((file) => (
                <Grid md={4} xs={12} sx={{ mb: 2 }} key={file.type}>
                    <Box sx={{
                        border: '1px solid #E7EAEC'
                    }}>
                        <PopupState variant="popover"
                            popupId="demo-popup-popover">
                            {
                                (popupState) => (
                                    <Box sx={{ border: '1px solid #E7EAEC', borderWidth: '2px 0px 1px 0px' }} >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={0}
                                            sx={{ p: 2 }}
                                            {...bindTrigger(popupState)}
                                        >
                                            {getFloorTypeConfig(file.type).icon()}
                                            <Stack direction="row" alignItems='center'>
                                                <Typography sx={{ color: "#696B6D" }}>
                                                    <b>{getFloorTypeConfig(file.type).title}</b>
                                                </Typography>
                                                {file.uploaded && <Stack sx={{ ml: 1 }}>
                                                    <UploadFile color="primary" />
                                                </Stack>}
                                            </Stack>
                                            <div className="ml-auto">
                                                <IconButton
                                                    size="small"
                                                    sx={{
                                                        height: 24,
                                                        "&:hover": {
                                                            borderRadius: 0
                                                        }
                                                    }}
                                                    variant="contained"

                                                >
                                                    {file.uploading && <CircularProgress size={20} />}
                                                    {!file.uploading && <MoreVertIcon size={20} />}
                                                </IconButton>
                                            </div>
                                        </Stack>
                                        {!file.uploading && <Popover
                                            sx={{ right: 50 }}
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right"
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: 'right'
                                            }}
                                        >
                                            <Stack direction="column" gap={0} sx={{ minWidth: 150 }}>
                                                {file.downloadUrl && (
                                                    <>
                                                        {
                                                            file?.type === 'lp2bin' && (
                                                                <Button
                                                                    variant="text"
                                                                    disabled={validateLoading}
                                                                    sx={{ justifyContent: 'left', color: '#696B6D', pl: 2, pr: 2 }}
                                                                    onClick={() => {
                                                                        navigateToViewFile(file)
                                                                    }}
                                                                >
                                                                    View File
                                                                </Button>
                                                            )
                                                        }

                                                        <Button
                                                            variant="text"
                                                            onClick={() => { downloadURI(file.downloadUrl); popupState.close() }
                                                            }
                                                            disabled={validateLoading}
                                                            sx={{ justifyContent: 'left', color: '#696B6D', pl: 2, pr: 2 }}

                                                        >
                                                            Download
                                                        </Button>
                                                    </>
                                                )}
                                                <Button component="label" disabled={validateLoading}
                                                    sx={{ justifyContent: 'left', color: '#696B6D', pl: 2, pr: 2 }}
                                                >
                                                    Upload
                                                    <input
                                                        accept="text/csv"
                                                        type="file"
                                                        hidden
                                                        onChange={(e) => {
                                                            uploadFile(e, file); popupState.close()
                                                        }
                                                        }
                                                    />
                                                </Button>
                                            </Stack>
                                        </Popover>}
                                    </Box>
                                )
                            }
                        </PopupState>
                        {/* Existing File */}
                        {(file.filename || file.updatedAt || file.username) && (
                            <FileInfo fileName={file.filename} updatedAt={file.updatedAt} username={file.username} fileUploaded={false} />
                        )}
                        {/* New uploaded file */}
                        {(file.uploaded && file.uploadedFileName) && (
                            <FileInfo fileName={file.uploadedFileName} updatedAt={new Date()} username={userName} fileUploaded={true} />
                        )}
                        <DropZoneArea
                            handleChange={(files) => {
                                uploadFile({
                                    target: {
                                        files: files
                                    }
                                }, file)
                            }}
                            onError={(error) => {
                                setMessage({ type: 'error', text: error })
                            }}
                            isFilesCleared={!file.uploaded}
                        />
                    </Box>
                </Grid>
            ))}
        </>
    )
}


export default ActiveWarehouseFiles