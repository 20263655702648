
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import { useState } from 'react';
import { Stack } from "@mui/system";
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
const TableContainerWrapper = styled.div`
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none;
    background: none;
  }
  .css-1ex1afd-MuiTableCell-root {
  }
  table thead tr th {
    border: 0px;
    color: #58595b;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 10px;
  }
  table tbody tr td {
    border: 1px solid #e3e8ef;
    padding: 10px 10px;
    font-size: 16px;
    color: #58595b;
    font-weight: 400;
  }
  table tbody tr:nth-of-type(even) td {
    background: #f8fafc;
  }
`;
const StyleInput = styled.input`
width:100%;
border:0px;
outline:none;
background:white;
height:30px;
`
const GridTable = ({ columns, rows, onChangeRow }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [editingRowIndex, setEditingRowIndex] = useState(-1)
    const [editingColumnIndex, setEditingColumnIndex] = useState(-1)
    const [inputWidth, setInputWidth] = useState(0)
    const handleClickRow = ({ row, column, rowIndex, columnIndex }) => {
        if (!!column?.editable) {
            setEditingRowIndex(rowIndex)
            setEditingColumnIndex(columnIndex)
            setEditedData({
                value: row[column?.key],
                key: column?.key
            })
            setIsEditing(true)
        }

    }
    const handleCloseEditable = () => {
        setEditingRowIndex(-1)
        setEditingColumnIndex(-1)
        setEditedData({})
        setIsEditing(false)
    }

    const handleChange = (e) => {
        const updateEditedData = { ...editedData, value: e.target.value }
        setEditedData(updateEditedData)
        onChangeRow(updateEditedData)
    }
    const handleSubmit = () => {

    }

    return <TableContainerWrapper>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column?.headerName}>
                                {column?.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        !!rows?.length && rows?.map((row, rowIndex) => (
                            <TableRow key={rowIndex.toString()}>
                                {columns?.map((column, columnIndex) => (
                                    <TableCell
                                        key={column?.headerName}
                                        sx={column?.sx || {}}
                                    >
                                        {
                                            !!isEditing && rowIndex === editingRowIndex && columnIndex === editingColumnIndex ? (
                                                <Stack flexDirection="row" columnGap={1} >
                                                    <StyleInput defaultValue={editedData?.value} onChange={handleChange} />
                                                    <Stack flexDirection="row" columnGap={1} alignItems="center" sx={{ marginLeft: 'auto' }}>
                                                        <Button variant="text" sx={{ padding: 0, minWidth: 20 }} onClick={handleSubmit} >
                                                            <DoneIcon sx={{ fontSize: 18 }} />
                                                        </Button>
                                                        <Button variant="text" sx={{ padding: 0, minWidth: 20 }} onClick={handleCloseEditable} >
                                                            <CloseIcon sx={{ fontSize: 18 }} color="error" />
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            ) : (
                                                <Stack
                                                    onClick={(e) => {
                                                        setInputWidth(e.target.offsetWidth)
                                                        handleClickRow({ row, column, rowIndex, columnIndex });
                                                    }}>
                                                    {column.render
                                                        ? column.render({ row, rowIndex })
                                                        : row[column?.key] ? row[column?.key] : ''}
                                                </Stack>
                                            )
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </TableContainerWrapper >
}

export default GridTable