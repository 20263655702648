import * as React from "react"
import { useEffect, useState, createRef } from "react"
import { useParams } from "react-router-dom"
import { PowerBIEmbed } from "powerbi-client-react"
import { models } from "powerbi-client"
import axios from "axios"
import { powerbiLightTheme } from "../../powerbi-themes/light-theme"
// import { powerbiDarkTheme } from "../powerbi-themes/dark-theme"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const PowerBi = function(props) {
  let tokenExpiration = null
  let containerRef = createRef()
  let condensedName = '';
  let domain = '';
  let dbName = '';
  let { clientId, warehouseId, userName, userId, accessToken, reportName, reportResolution } = useParams()
  console.log('reportName: ', reportName);
  console.log('reportResolution: ', reportResolution);
  let prevWarehouseId = null;
  let slicers = []
  let filters = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
        table: "databaseInfo",
        column: "databaseSelected"
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: ""
      },
    ],
  };
  let initialConfigState = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    id: "",
    permissions: 0,
    datasetBinding: {
      datasetId: "notOriginalDatasetId"
    },
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        },
        pageNavigation: {
          visible: false
        }
      },
      layoutType:
        window.screen.width <= 480
          ? models.LayoutType.MobilePortrait
          : models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToWidth
      }
    },
    theme: {
      themeJson: powerbiLightTheme
    },
    slicers: slicers || null,
    filters: [filters],
  }
  let [hideElement, setHideElement] = useState(true)
  let [showError, setShowError] = useState(false)
  const [reportConfig, setReportConfig] = useState(initialConfigState)

  const getParamFilters = async () => {
    const params = new URLSearchParams(window.location.search)

    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    )
    return paramsObj;
  }

  const buildfilters = filters => {
    //Example of filter saved in localstorage

    // let filters = [{
    //   table: 'floor',
    //   column: 'description',
    //   operator: 'In',
    //   values: ['Floor 2'],
    //   filterType: 'basic'
    // }]

    filters.forEach(item => {
      let filter
      if (item.filterType == "basic") {
        filter = {
          selector: {
            $schema: "http://powerbi.com/product/schema#slicerTargetSelector",
            target: {
              table: item.table,
              column: item.column
            }
          },
          state: {
            filters: [
              {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: item.table,
                  column: item.column
                },
                operator: item.operator,
                values: item.values,
                filterType: models.FilterType.Basic
              }
            ]
          }
        }
      } else if (item.filterType == "advanced") {
        filter = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: item.table,
            column: item.column
          },
          logicalOperator: item.operator,
          conditions: item.values,
          filterType: models.FilterType.Advanced
        }
      }
      slicers.push(filter)
    })
  }


  useEffect(() => {
    if(warehouseId && prevWarehouseId != warehouseId){
      prevWarehouseId = warehouseId;
      setHideElement(false)
      clearTimeout(window.powerBiRefresh)
      slicers = []
      let paramsObj = getParamFilters()
      for (const property in paramsObj) {
        if (property == "floor") {
          buildfilters([
            {
              table: "floor",
              column: "description",
              operator: "In",
              values: [paramsObj[property]],
              filterType: "basic"
            }
          ])
        } else if (property == "custowner") {
          buildfilters([
            {
              table: "custowner",
              column: "custownername",
              operator: "In",
              values: [paramsObj[property]],
              filterType: "basic"
            }
          ])
        } else if (property == "rotation") {
          buildfilters([
            {
              table: "Rotate",
              column: "Rotate",
              operator: "In",
              values: [`${paramsObj[property]}°`],
              filterType: "basic"
            }
          ])
        } else if (property == "tasktype") {
          buildfilters([
            {
              table: "TaskType",
              column: "TaskType",
              operator: "In",
              values: [`${paramsObj[property]}`],
              filterType: "basic"
            }
          ])
        }
      }
      initialConfigState = { ...initialConfigState, slicers: slicers || null }
      setReportConfig({ ...initialConfigState, slicers: slicers || null })
      // getReport(false, slicers);
      getWarehouseConfig(false, slicers);
    }
  }, [warehouseId])

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      "loaded",
      function() {
        console.log("Report has loaded")
        setHideElement(true)
      }
    ],
    [
      "rendered",
      function() {
        console.log("Report has rendered")
        setRefresh()
        // setMessage("The report is rendered")
      }
    ],
    [
      "error",
      function(event) {
        if (event) {
          console.error(event.detail)
          setHideElement(true)
        }
      }
    ]
  ])

  const setRefresh = async () => {
    clearTimeout(window.powerBiRefresh)
      window.powerBiRefresh = setTimeout(() => refreshReport(), 30000)
  }

  const checkTokenAndUpdate = () => {
    // Get the current time
    const currentTime = Date.now()
    const expiration = Date.parse(tokenExpiration)

    // Time until token expiration in milliseconds
    const timeUntilExpiration = expiration - currentTime
    const timeToUpdate = 10 * 60 * 1000

    // Update the token if it is about to expired
    if (timeUntilExpiration <= timeToUpdate) {
      getReport(true)
    }
  }

  const refreshReport = async () => {
    try {
      checkTokenAndUpdate()
      // add back for auto refresh
      // await window.reportObject.refresh()
      // console.log("Refreshed")
    } catch (errors) {
      console.log("Refresh report error", errors)
      setRefresh()
    }
  }


  const getWarehouseConfig = async (updateToken = false, slicers = []) => {
    setShowError(false);
    if(warehouseId && warehouseId != null && warehouseId != 'null'){
      return await axios
        .get(
          `${process.env.REACT_APP_SERVICES_API}/services/site/find?legacySiteId=${warehouseId}`,
          {
            headers: {
              "locus-user": `{"username":"${userName}"}`,
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`,
            }
          }
        )
        .then(response => {
          condensedName = response.data.condensedName;
          domain = response.data.client.domain;
          if(!response?.data?.biParameters?.databaseName){
            return setShowError(true);
          }
          else{
            dbName = response.data.biParameters.databaseName;
            filters.conditions[0].value = dbName;
            getReport(updateToken, slicers, filters)
          }
        })
        .catch(err => {
          setShowError(true);
          console.log("getReport err", err)
        })
    }
  }

  // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding
  const getReport = async (updateToken = false, slicers = [], filters = []) => {
    if (!updateToken) {
      setReportConfig(initialConfigState)
      window.reportObject = null
    }
    if (clientId) {
      let datasetIdBase = `REACT_APP_POWERBI_DATASETID_${reportName}`;
      const reportResolutionMap = {
        1: 'DAILY',
        2: 'HOURLY',
        3: 'MONTHLY',
        4: 'YEARLY',
      };
      const reportNameEnv = (reportResolution !== '0')  ? datasetIdBase + `_${reportResolutionMap[reportResolution]}` : datasetIdBase;
      console.log(reportNameEnv, 'reportNameEnv')
      console.log(process.env.REACT_APP_POWERBI_REPORTID, 'process.env.REACT_APP_POWERBI_REPORTID')
      return await axios
        .get(
          // `${process.env.REACT_APP_POWERBI_API}/analytics/report/${process.env.REACT_APP_POWERBI_REPORTID}`,
          `${process.env.REACT_APP_POWERBI_API}/analytics/report/${process.env[reportNameEnv]}`,
          {
            headers: {
              "locus-domain": domain,
              "locus-warehouse": condensedName,
              "locus-user": `{"username":"${userName}"}`,
              "locus-client-id": clientId,
              "locus-dataset-id": process.env.REACT_APP_POWERBI_DATASETID,
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`,
              "locus-portal-client-id": clientId,
              "locus-portal-user-id": userId,
              "locus-portal-warehouse-id": warehouseId,
            }
          }
        )
        .then(response => {
          setHideElement(true);
          tokenExpiration = response.data.tokenExpiration
          if (!updateToken) {
            setReportConfig({
              ...reportConfig,
              embedUrl: response.data.embedUrl,
              accessToken: response.data.token,
              id: response.data.reportId,
              datasetBinding: {
                datasetId: response.data.datasetId
              },
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false
                  },
                  pageNavigation: {
                    visible: true
                  }
                },
                layoutType:
                  window.screen.width <= 480
                    ? models.LayoutType.MobilePortrait
                    : models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth
                }
              },
              theme: {
                themeJson: powerbiLightTheme
              },
              slicers: slicers.length ? slicers : null,
              filters: [filters]
            })
          } else {
            console.log("reset token")
            window.reportObject.setAccessToken(response.data.token)
          }
        })
        .catch(err => {
          console.log("getReport err", err)
        })
    }
  }

  return (
    <div style={{height: '100%'}}
    >
      {!hideElement && (
        <div> 
          {!showError &&
          <Box sx={{ display: 'flex' }} style={{marginLeft: '45%', marginTop: '10%'}}>
              <CircularProgress size={`100px`} />
          </Box>
          }
          {showError &&
          <Box sx={{ display: 'flex', mt: 2 }} style={{alignItems: "center", justifyContent: "center"}} border>
              Executive Summary Coming Soon - We are working to bring the Executive Summary report to you as soon as possible. Thanks for your patience!
          </Box>
          }
        </div>
      )}
      {
        hideElement &&
        <div style={{height: '100%'}}>
          <div style={{height: '100%'}} ref={containerRef}>
            <PowerBIEmbed
              embedConfig={reportConfig}
              eventHandlers={eventHandlersMap}
              cssClassName={
                window.screen.width <= 480 && hideElement
                  ? "report-style-class report-style-class-mobile"
                  : "report-style-class"
              }
              getEmbeddedComponent={embedObject => {
                window.reportObject = embedObject
              }}
            />
          </div>
      </div>
      }
    </div>
  )
}

export default PowerBi
