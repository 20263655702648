import React, { useState, useRef, useEffect } from 'react';
import { Stack } from "@mui/system";
import { Typography } from '@mui/material';

const DropZoneArea = ({ handleChange, onError, isFilesCleared }) => {
    const [highlight, setHighlight] = useState(false);
    const [file, setFile] = useState(null);
    const inputRef = useRef(null);
    const allowedTypes = ['text/csv'];

    const handleDragEnter = (e) => {
        e.preventDefault();
        setHighlight(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setHighlight(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setHighlight(false);
        const droppedFiles = Array.from(e.dataTransfer.files);

        if (droppedFiles.length > 1) {
            const lengthError = 'Uploading multiple files is not permitted.';
            return onError(lengthError);
        }

        if (!allowedTypes.includes(droppedFiles[0]?.type)) {
            const fileTypeError = 'Only CSV files are permitted.';
            return onError(fileTypeError);
        }

        setFile(droppedFiles[0]);
        handleChange(droppedFiles);
    };

    const handleFileInputChange = (e) => {
        setFile(e.target.files[0]);
        handleChange(e.target.files);
    };

    useEffect(() => {
        if (isFilesCleared) {
            setFile(null);
            inputRef.current.value = "";
        }
    }, [isFilesCleared])

    return (
        <Stack
            onDragEnter={handleDragEnter}
            onDragOver={(e) => {
                e.preventDefault()
                setHighlight(true)
            }}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => inputRef.current.click()}
            justifyContent="center"
            sx={{
                border: '1px dashed #53B095',
                margin: 1,
                height: 40,
                background: !!highlight ? 'rgba(83, 176, 149, 0.6)' : 'rgba(83, 176, 149, 0.1)',
                cursor: 'pointer'
            }}
        >
            <input
                type="file"
                style={{ display: 'none' }}
                accept=".csv"
                onChange={handleFileInputChange}
                ref={inputRef}
            />
            <Typography color="#53B095" sx={{ fontSize: 12, textAlign: 'center', margin: 2 }}>
                {file?.name ? file?.name : '  Drag and drop or browse to choose the file'}
            </Typography>
        </Stack>
    );
};

export default DropZoneArea;
