import { useEffect, useState } from "react";
import { Switch, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, useParams } from "react-router-dom";
import SearchSites from "../../components/SearchSites";
import Input from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useRoot } from "../../context/RootProvider";
import Pagination from "../../components/Pagination";
import { makeRequest } from "../../utils";
import { apiConfigs } from "../../services";
import GridTable from "../../components/GridTable";
const errorSx = {
    background: "#F0BCA5 !important",
    color: "#E45517 !important",
};
const radioSx = {
    "&.Mui-checked": {
        color: "#4182C1",
    },
};
const switchSx = {
    "&.MuiSwitch-root .Mui-checked .MuiSwitch-thumb": {
        color: "#4182C1",
    },

    "&.MuiSwitch-root .MuiSwitch-thumb": {
        color: "#8C8D8E",
    },

    "&.MuiSwitch-root .MuiSwitch-track": {
        opacity: 0.25,
    },

    "&.MuiSwitch-root .Mui-checked + .MuiSwitch-track": {
        background: "#4182C1",
    },
};

const SiteConfigView = () => {
    const navigate = useNavigate();
    let { userName, accessToken, floorId } = useParams();
    const { client, domain, locusWarehouse } = useRoot();
    const [params, setParams] = useState({
        total: 1,
        page: 1,
        size: 50,
        floor: floorId,
        lpSearch: '',
        locationSearch: '',
    });
    const [rows, setRows] = useState([]);
    const [pagination, setPagination] = useState({ total: 1, pages: 1 })
    const [searchField, setSearchField] = useState('lp');

    const getLp2BinRows = async (params) => {
        try {
            const resp = await makeRequest(
                apiConfigs.getLp2BinRows({
                    headers: {
                        "locus-warehouse": locusWarehouse,
                        "locus-client-id": client?.ClientId,
                        "locus-user": `{"username":"${userName}"}`,
                        authorization: `Bearer ${accessToken}`,
                        "locus-domain": domain,
                        "locus-region": process.env.REACT_APP_LOCUS_REGION,
                        "Content-Type": "application/json"
                    },
                    params: {
                        size: params?.size,
                        page: params?.page,
                        floor: params?.floor,
                        lpSearch: params?.lpSearch,
                        locationSearch: params?.locationSearch
                    },
                })
            );
            setRows(resp.pagedData)
            setPagination({ total: resp.total, pages: resp.pages })
        } catch (err) {
            console.log(err);
        }
    };

    const handleSearchViewFile = (event) => {
        if (searchField === 'lp') {
            const updatedParams = { ...params, lpSearch: event.target.value }
            setParams(updatedParams)
        }
        if (searchField === 'location') {
            const updatedParams = { ...params, locationSearch: event.target.value }
            setParams(updatedParams)
        }
    };

    const handleRadioFilter = (event) => {
        setSearchField(event.target.value)
    };

    const handleExportViewFile = async (params) => {
        try {
            let link = document.createElement("a");
            const resp = await makeRequest(
                apiConfigs.exportCurrentView({
                    headers: {
                        "locus-warehouse": locusWarehouse,
                        "locus-client-id": client?.ClientId,
                        "locus-user": `{"username":"${userName}"}`,
                        authorization: `Bearer ${accessToken}`,
                        "locus-domain": domain,
                        "locus-region": process.env.REACT_APP_LOCUS_REGION,
                        "Content-Type": "application/json"
                    },
                    params: {
                        floor: params?.floor,
                        lpSearch: params?.lpSearch,
                        locationSearch: params?.locationSearch
                    },
                })
            );
            link.href = resp.sasToken.combinedUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.log(err);
        }
    };
    const handlePageSize = (event) => {
        const updateParams = { ...params, page: 1, size: event.target.value };
        setParams(updateParams);
    };

    const columns = [
        {
            headerName: "",
            key: "_",
            render: ({ row, rowIndex }) => {
                return (params?.page - 1) * params?.size + rowIndex + 1
            }
        },
        {
            headerName: "Location",
            key: "Location",
        },
        {
            headerName: "LP",
            key: "LP",
        },
        {
            headerName: "Username",
            key: "UserName",
        },
        {
            headerName: "Proc_Name",
            key: "proc_name",

        },
    ];
    useEffect(() => {
        getLp2BinRows(params);
    }, [params]);
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    mb: 5,
                }}
            >
                <SearchSites />
            </Box>
            <Box sx={{ pr: 3, pl: 3, pt: 2, pb: 2 }}>
                <Stack flexDirection="row" justifyContent="space-between">
                    <Stack>
                        <Typography sx={{ fontSize: 24, fontWeight: 300 }}>
                            View File: <b style={{ fontWeight: 400 }}>Sample File.csv</b>
                        </Typography>
                    </Stack>
                    <Stack sx={{pr: 2}}>
                        <Button
                            onClick={() => {
                                navigate(-1);
                            }}
                            variant="contained"
                            sx={{
                                textTransform: "initial",
                                background: "#E7EAEC",
                                border: "1px solid #A7A7A7",
                                color: "#000",
                                fontSize: 12,
                                boxShadow: 0,
                                fontWeight: 600,
                                "&:hover": {
                                    boxShadow: 0,
                                    background: "none",
                                },
                            }}
                        >
                            Return to File Upload
                        </Button>
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        background: "#fff",
                        pt: 2,
                        pb: 6,
                        pr: 2,
                        pl: 2,
                        mt: 2,
                        borderRadius: 1,
                        border: "1px solid #E3E8EF",
                    }}
                >
                    <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                        flexWrap="wrap"
                        rowGap={1}
                    >
                        <Stack flexDirection="row" alignItems="center" columnGap={3}>
                            <Input
                                disabled={!searchField}
                                disableUnderline
                                sx={{
                                    border: "1px solid #E5E6E7",
                                    background: "#fff",
                                    height: 45,
                                    textIndent: 7,
                                    borderRadius: 1,
                                    padding: "0px 10px",
                                    fontSize: 14,
                                }}
                                placeholder="Search"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                }
                                onChange={handleSearchViewFile}
                            />
                            <Stack>
                                <RadioGroup row name="radioFilter" value={searchField} onChange={handleRadioFilter}>
                                    <FormControlLabel
                                        value="lp"
                                        control={<Radio sx={radioSx} />}
                                        label="LP"
                                    />
                                    <FormControlLabel
                                        value="location"
                                        control={<Radio sx={radioSx} />}
                                        label="Location"
                                    />
                                </RadioGroup>
                            </Stack>
                        </Stack>
                        <Stack flexDirection="row" columnGap={2} alignItems="center">
                            {/* <Stack flexDirection="row" alignItems="center">
                                <Switch sx={switchSx} />
                                <Typography
                                    sx={{ fontSize: 13, color: "#686A6B", fontWeight: 400 }}
                                >
                                    Only Errors (1)
                                </Typography>
                            </Stack> */}
                            <Stack>
                                <Select
                                    onChange={handlePageSize}
                                    value={params?.size}
                                    sx={{
                                        height: 35,
                                        fontSize: 13,
                                        fontWeight: 400,
                                        color: "#696B6D",
                                    }}
                                >
                                    <MenuItem value={50}>50 Rows</MenuItem>
                                    <MenuItem value={100}>100 Rows</MenuItem>
                                    <MenuItem value={200}>200 Rows</MenuItem>
                                </Select>
                            </Stack>
                            <Stack>
                                <Button
                                    onClick={() => handleExportViewFile(params)}
                                    sx={{
                                        textTransform: "capitalize",
                                        fontSize: 12,
                                        height: 35,
                                        boxShadow: 0,
                                        "&:hover": {
                                            boxShadow: 0,
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Export Current View
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <GridTable columns={columns} rows={rows} onChangeRow={(text, key) => {
                        console.log(text, key)
                    }} />
                    <Pagination
                        pageSize={params?.size}
                        total={pagination.total}
                        totalPage={pagination.pages}
                        currentPage={params?.page}
                        onPageChange={(page) => {
                            const updateParams = { ...params, page: page };
                            setParams(updateParams);
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default SiteConfigView;
