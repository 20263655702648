import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 as uuidv4 } from 'uuid';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import { apiConfigs } from "../../services";
import { makeRequest } from "../../utils";
import { useParams, useNavigate } from "react-router-dom"
import Modal from '@mui/material/Modal'
import styled from "@emotion/styled";
import ActiveWarehouseFiles from "./ActiveWarehouseFiles";
import SuccessSummary from "./SuccessSummary";
import ErrorSummary from "./ErrorSummary";
import FloorForm from "./FloorForm";
import Info from "./Info";
import SearchSites from "../../components/SearchSites";
import { useRoot } from "../../context/RootProvider";
let locusWarehouse = null;
let domain = null;
let locusHeader = {}

const SUCCESS_MESSAGE = "Success";
const FAILURE_MESSAGE = "Request could not be processed. Please try again.";

const Accordion = styled(MuiAccordion)`
    &::before{
        background:none;
    }
`
const AccordionSummary = styled(MuiAccordionSummary)
    ` &.Mui-focusVisible {
    background-color: inherit
}
&.Mui-disabled{
    background:none;
    opacity:1;
}
`
const AccordionDetails = styled(MuiAccordionDetails)`
.MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-4.MuiGrid2-grid-xs-12{
    margin-bottom:10px;
    padding-top:0px;
}
`
function Welcome() {
    const navigate = useNavigate()
    const { warehouse, client, setDisabledConfirm, setMessage, setDomain, setLocusWarehouse } = useRoot()
    const [floor, setFloor] = useState("floor_1");
    const [floorsInfo, setFloorsInfo] = useState([])
    const [floorSubmitLoading, setFloorSubmitLoading] = useState(false)
    const [requestId, setRequestId] = useState(null);
    const [activeWarehouseFiles, setActiveWarehouseFiles] = useState({});
    const [warehouseSASForUpload, setWarehouseSASForUpload] = useState({});
    const [validated, setValidated] = useState({});
    const [validateLoading, setValidateLoading] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitSuccessData, setSubmitSuccessData] = useState({})
    const [validateData, setValidateData] = useState({});
    const [validateDisabled, setValidateDisabled] = useState({});
    const [expanded, setExpanded] = useState(1);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false)
    const [submitIndex, setFloorSubmitIndex] = useState(0)
    const [progressStatus, setProgressStatus] = useState({})
    const [isFilesUploaded, setIsFilesUploaded] = useState(false);

    let { userName, userId, accessToken } = useParams()

    const isSubmitDisabled = !!Object.keys(validated).length ? !Object.keys(validated).every(key => Boolean(validated[key])) : true
    const handleChange =
        (panel, floorId) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
            setFloor(floorId)
            if (!activeWarehouseFiles[floorId]?.length) {
                getWarehouseSASForUpload(floorId)
            }
        };

    const getFloors = async (fl) => {
        locusHeader = { ...locusHeader, "locus-client-id": client.ClientId }
        return makeRequest(apiConfigs.getFloors({ headers: locusHeader })).then(response => {
            const floorLength = response.floors.length
            setFloorsInfo(!!floorLength ? response.floors?.map((floor) => {
                return {
                    ...floor,
                    isEdit: true
                }
            }) : [{ description: 'Floor 1', floorId: 'floor_1', isEdit: false }])
            setFloor(fl ? fl : !!floorLength ? response.floors[0].floorId : 'floor_1')
        }).catch((err) => {
            setMessage({ type: 'error', text: FAILURE_MESSAGE })
        })
    }


    const getActiveWarehouseFiles = async (data, fl, isSubmit = false) => {
        locusHeader = { ...locusHeader, "locus-client-id": client.ClientId }
        return makeRequest(apiConfigs.getActiveWarehouseFiles({
            params: {
                floor: fl || (`${client.ClientName}-${warehouse.WarehouseId}`).replace(/\s/g, ''),
            },
            headers: locusHeader,
        }))
            .then((response) => {
                let activeFiles = response?.[fl] || [];
                if (activeFiles?.length) {
                    data.forEach((file, i) => {
                        activeFiles.forEach((item) => {
                            if (file.type == item.type) {
                                data[i] = { ...file, ...item };
                            }
                        });
                    });
                }
                else {
                    data = data.filter(item => item.type != 'lp2binUpdate')
                }
                return setActiveWarehouseFiles(!!isSubmit ? { [fl]: data } : { ...activeWarehouseFiles, [fl]: data });

            })
            .catch((err) => {
                setMessage({ type: 'info', text: 'No Active files uploaded for client/warehouse' })
                return setActiveWarehouseFiles({ ...activeWarehouseFiles, [fl]: data.filter(item => item.type != 'lp2binUpdate') });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getWarehouseConfig = async () => {
        setLoading(true)
        setWarehouseSASForUpload({})
        setActiveWarehouseFiles({})
        return makeRequest(apiConfigs.getWarehouseConfig({
            params: {
                legacySiteId: warehouse.WarehouseId,
            },
            headers: locusHeader,
        }))
            .then(async (response) => {
                domain = response.client.domain
                locusWarehouse = response.condensedName
                locusHeader = { ...locusHeader, "locus-warehouse": locusWarehouse, "locus-domain": domain, "locus-user": `{"username":"${userName}"}` }
                setDomain(domain)
                setLocusWarehouse(response.condensedName)
                await getFloors()
                await getWarehouseSASForUpload(floor);
            })
            .catch((err) => {
                setLoading(false)
                setMessage({ type: 'error', text: 'Site is not setup' })
            })
    };

    const getWarehouseSASForUpload = async (fl, isSubmit = false) => {
        locusHeader = { ...locusHeader, "locus-client-id": client.ClientId }
        let request = uuidv4();
        setWarehouseSASForUpload({})
        setActiveWarehouseFiles({})
        if (!isSubmit && !submitSuccess) {
            setValidateData({ ...validateData, [fl]: null })
            setValidateDisabled({ ...validateDisabled, [fl]: true })
            setValidateLoading(false)
            setDisabledConfirm(false)
        }
        return makeRequest(apiConfigs.getWarehouseSASForUpload({
            params: {
                floor: fl || (`${client.ClientName}-${warehouse.WarehouseId}`).replace(/\s/g, ''),
                requestId: request,
            },
            headers: locusHeader,
        }))
            .then((response) => {
                setRequestId(request);
                getActiveWarehouseFiles(response?.tokens, fl, isSubmit);
                return setWarehouseSASForUpload({ ...warehouseSASForUpload, [fl]: response?.tokens || [] });
            })
            .catch((err) => {
                setLoading(false)
                setMessage({ type: 'error', text: FAILURE_MESSAGE })
            });
    };

    const uploadFile = async (e, data) => {
        locusHeader = { ...locusHeader, "locus-client-id": client.ClientId, "locus-domain": domain }
        const file = e.target.files[0];
        let activeFiles = [...activeWarehouseFiles[floor]]

        if (file.type !== "text/csv") {
            return setMessage({ type: 'error', text: 'File type must be .csv' })
        }

        activeFiles.forEach(item => {
            if (item.type == data.type) {
                item.uploading = true
            }
        })
        setProgressStatus({ total: 0 })
        setValidateDisabled({ ...validateDisabled, [floor]: true })
        setActiveWarehouseFiles({ ...activeWarehouseFiles, [floor]: activeFiles });
        // todo add file name to array if missing
        return makeRequest(apiConfigs.uploadFile({
            url: data.url,
            data: file,
            headers: {
                "x-ms-meta-contenttype": file.type,
                "x-ms-meta-originalfile": file.name,
                "x-ms-meta-requestid": "abcd",
                "x-ms-meta-filetype": data.type,
                "x-ms-meta-floor": floor,
                "x-ms-blob-type": "BlockBlob",
                "x-ms-meta-user":
                    `"{\\"username\\": \\"${userName}\\"}"`,
                "x-ms-meta-clientid": client.ClientId,
                "x-ms-meta-domain": domain,
                "x-ms-meta-warehouse": locusWarehouse,
                "x-ms-meta-username": userName,
                "x-ms-meta-customer": client.ClientName,
                "x-ms-meta-site": warehouse.WarehouseFriendlyName,
            },

        }), false)
            .then((response) => {
                setValidated({ ...validated, [floor]: false })
                setMessage({ type: 'success', text: SUCCESS_MESSAGE })
                let activeFiles = [...activeWarehouseFiles[floor]]
                activeFiles.forEach(item => {
                    if (item.type == data.type) {
                        item.uploaded = true
                        item.uploading = false
                        item.uploadedFileName = file.name
                    }
                })
                let uploadCount = 0;
                activeFiles.slice(0, 3).forEach(item => {
                    if (item.uploadDate || item.uploaded) uploadCount++
                })
                if (uploadCount === 3) {
                    setValidateDisabled({ ...validateDisabled, [floor]: false })
                }
                setActiveWarehouseFiles({ ...activeWarehouseFiles, [floor]: activeFiles });
                setIsFilesUploaded(true);
            })
            .catch((err) => {
                let activeFiles = [...activeWarehouseFiles]
                activeFiles.forEach(item => {
                    if (item.type == data.type) {
                        item.uploading = false
                    }
                })
                setActiveWarehouseFiles({ ...activeWarehouseFiles, [floor]: activeFiles });
                setIsFilesUploaded(false);
                setMessage({ type: 'error', text: FAILURE_MESSAGE })
            });
    };

    const downloadURI = async (uri, name) => {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const validateFiles = async (clientId) => {
        locusHeader = { ...locusHeader, "locus-client-id": client.ClientId, "locus-domain": domain }
        setValidateData({ ...validateData, [floor]: null });
        setValidated({ ...validated, [floor]: false });
        setSubmitSuccess(false)
        setSubmitSuccessData({ ...submitSuccessData, [floor]: null })
        setValidateLoading(true)
        setDisabledConfirm(true)
        setMessage({ type: 'info', text: 'Hey! This might take a while.' })
        return makeRequest(apiConfigs.validateFiles({
            data: {
                floor: floor || (`${client.ClientName}-${warehouse.WarehouseId}`).replace(/\s/g, ''),
                requestId: requestId,
            },
            headers: locusHeader
        }))
            .then((response) => {
                setTimeout(() => {
                    getValidStatus();
                }, 2000);
            })
            .catch((err) => {
                setValidateLoading(false)
                setDisabledConfirm(false)
                setMessage({ type: 'error', text: FAILURE_MESSAGE })
            })
    };

    const getValidStatus = async () => {
        return makeRequest(apiConfigs.getValidStatus({
            params: {
                requestId: requestId,
            },
            headers: locusHeader
        }))
            .then((response) => {
                setProgressStatus(response?.progress || { total: 0 })
                if (response.status === 'error') {
                    setMessage({ type: 'error', text: response.message })
                }
                else if (response.status === 'complete') {
                    setValidateDisabled({ ...validateDisabled, [floor]: true })
                    setMessage({ type: 'success', text: 'Completed' })
                }
                if (response.status != 'validating' && response.status != 'pending') {
                    setValidateLoading(false)
                    setDisabledConfirm(false)
                    setValidated({ ...validated, [floor]: response.status !== 'error' && !response.summary?.uiErrors && response.summary?.errorsCount == 0 })
                    return setValidateData({ ...validateData, [floor]: response });
                }
                else {
                    setTimeout(() => {
                        getValidStatus();
                    }, 2000);
                }
                setProgressStatus({ total: 0 })

            })
            .catch((err) => {
                setValidateLoading(false)
                setDisabledConfirm(false)
                setMessage({ type: 'error', text: FAILURE_MESSAGE })
            });
    };
    const submitFiles = async () => {
        try {
            setSubmitLoading(true)
            setDisabledConfirm(true)
            const promises = []
            const successData = {}
            const validatedFloors = Object.keys(validated)
            validatedFloors?.map((key) => {
                promises.push(makeRequest(apiConfigs.submitFiles({
                    data: {
                        submitPaths: validateData[key].submitPaths,
                        floor: key || (`${client.ClientName}-${warehouse.WarehouseId}`).replace(/\s/g, ''),
                        userName: userName
                    },
                    headers: locusHeader
                })))
            })
            const responses = await Promise.all(promises)
            responses?.forEach(async (response, index) => {
                const floorId = validatedFloors[index]
                successData[floorId] = response
                setValidateDisabled({ ...validateDisabled, [floorId]: true })
            })
            setSubmitSuccessData(successData)
            setSubmitLoading(false)
            setDisabledConfirm(false)
            setValidated({})
            setSubmitSuccess(true)
            setMessage({ type: 'success', text: SUCCESS_MESSAGE })
            getWarehouseSASForUpload(floor, true)
        } catch (err) {
            setSubmitSuccess(false)
            setSubmitLoading(false)
            setDisabledConfirm(false)
            setMessage({ type: 'error', text: FAILURE_MESSAGE })
        }

    };
    const floorsUpdate = (oldFloorsInfo, updateValue, index) => {
        const updateFloorsInfo = [...oldFloorsInfo];
        updateFloorsInfo[index] = { ...oldFloorsInfo[index], ...updateValue };
        return updateFloorsInfo;
    }

    const handleFloorInfoConfirmation = (index) => {
        setVisible(true)
        setFloorSubmitIndex(index)
    }

    const handleFloorInfoSubmit = async (index) => {
        setFloorSubmitLoading(true)
        const floorInfo = floorsInfo[index]
        const data = {
            floorId: floorInfo.floorId,
            description: floorInfo.description,
        }
        if (floorInfo.id) {
            data.id = floorInfo.id
        }
        setVisible(false)
        return makeRequest(apiConfigs.createOrUpdateFloor({ data: data, headers: { ...locusHeader, "locus-client-id": client.ClientId } })).then(response => {
            setFloorsInfo(oldFloorsInfo => {
                return floorsUpdate(oldFloorsInfo, { ...response, isEdit: true }, index)
            })
            setFloorSubmitLoading(false)
            setMessage({ type: 'success', text: SUCCESS_MESSAGE })
        }).catch((err) => {
            setFloorSubmitLoading(false); setMessage({ type: 'error', text: FAILURE_MESSAGE })
        })

    }

    const handleClickConfirm = () => {
        setExpanded(1)
        setFloorsInfo([])
        getWarehouseConfig();
    }

    const navigateToViewFile = (file) => {
        navigate(`/site-config/view/${floor}/${userName}/${userId}/${accessToken}`)
    }

    // Remove uploaded files
    const removeUploadedFiles = () => {
        locusHeader = { ...locusHeader, "locus-client-id": client.ClientId, "locus-domain": domain }
        let activeFiles = [...activeWarehouseFiles[floor]];
        makeRequest(apiConfigs.removeUploadedFiles({
            headers: locusHeader,
            data: {
                floorId: floor
            }
        }))
            .then(response => {
                setValidated({ ...validated, [floor]: false });
                activeFiles.forEach(item => {
                    if (item.uploaded) {
                        delete item.uploaded;
                        delete item.uploading;
                    }
                })
                setValidateDisabled({ ...validateDisabled, [floor]: true })
                setIsFilesUploaded(false);
                setActiveWarehouseFiles({ ...activeWarehouseFiles, [floor]: activeFiles });
                setMessage({ type: 'success', text: 'Files Removed' });
            })
            .catch(err => {
                setMessage({ type: 'error', text: FAILURE_MESSAGE })
            })

    }

    const isAccordionDisabled = (floorId) => {
        return isFilesUploaded && floorId !== floor
    }

    useEffect(() => {
        locusHeader = {
            "locus-warehouse": locusWarehouse,
            "locus-client-id": '',
            "locus-user": `{"username":"${userName}"}`,
            "locus-domain": domain,
            "authorization": `Bearer ${accessToken}`,
            "locus-region": process.env.REACT_APP_LOCUS_REGION
        }
        if (!!warehouse && !!client) {
            getWarehouseConfig();
        }
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    mb: 5
                }}
            >
                <SearchSites handleClickConfirm={handleClickConfirm} />
            </Box>

            {!floorsInfo?.length && (
                <Info />
            )}

            {!!floorsInfo.length && !loading && (
                <Box sx={{ pr: 3, pl: 3, pt: 2, pb: 2 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <h2 style={{ margin: '0px 0px 10px 0px' }}>Site Files</h2>
                        <LoadingButton
                            className="ml-auto"
                            color="primary"
                            variant="contained"
                            sx={{
                                height: 30, fontSize: 12, minWidth: 130, fontWeight: 400, mr: 2, boxShadow: 0, "&:hover": {
                                    boxShadow: 0
                                }
                            }}
                            onClick={() => submitFiles()}
                            loading={submitLoading}
                            disabled={isSubmitDisabled}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                    {
                        floorsInfo?.map((floorItem, i) => {
                            const validateDisabledStatus = typeof validateDisabled[floorItem?.floorId] === 'undefined' ? true : validateDisabled[floorItem?.floorId]
                            const floorValidateData = validateData[floorItem?.floorId]
                            return (
                                <Accordion disabled={isAccordionDisabled(floorItem.floorId)} expanded={expanded === i + 1} onChange={handleChange(i + 1, floorItem.floorId)} key={i + 1} sx={{ marginTop: 2 }} style={{ boxShadow: 'none' }} >
                                    <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        disabled={validateLoading}
                                        sx={{ width: "100%" }}
                                    >
                                        <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between">
                                            <FloorForm
                                                i={i}
                                                floorSubmitLoading={floorSubmitLoading}
                                                floorItem={floorItem}
                                                floorsUpdate={floorsUpdate}
                                                floorsInfo={floorsInfo}
                                                setFloorsInfo={setFloorsInfo}
                                                handleFloorInfoConfirmation={handleFloorInfoConfirmation}
                                            />
                                            <Stack direction="row" gap={1}>
                                                <Stack justifyContent="center" onClick={(event) => {
                                                    event.stopPropagation();
                                                }}>
                                                    <Button
                                                        color="error"
                                                        variant="contained"
                                                        sx={{
                                                            height: 30, fontSize: 12, minWidth: 130, fontWeight: 400, boxShadow: 0, "&:hover": {
                                                                boxShadow: 0
                                                            }
                                                        }}
                                                        onClick={() => removeUploadedFiles()}
                                                        disabled={isAccordionDisabled(floorItem.floorId) || !isFilesUploaded}
                                                    >
                                                        Clear Uploaded Files
                                                    </Button>
                                                </Stack>
                                                <Stack justifyContent="center" onClick={(event) => {
                                                    event.stopPropagation();
                                                }}>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        sx={{
                                                            height: 30, fontSize: 12, minWidth: 130, fontWeight: 400, boxShadow: 0, "&:hover": {
                                                                boxShadow: 0
                                                            }
                                                        }}
                                                        onClick={() => !validateLoading && !validateDisabledStatus && validateFiles()}
                                                        disabled={validateDisabledStatus}
                                                        fontSize='small'
                                                    >
                                                        <Stack direction='row' gap={1}>
                                                            {validateLoading && !validateDisabledStatus && <CircularProgress sx={{ color: '#fff' }} size={18} thickness={5} variant="determinate" value={progressStatus?.total || 0} />}
                                                            {validateLoading && !validateDisabledStatus && `${progressStatus?.total || 0}%`}
                                                            {validateLoading && !validateDisabledStatus ? ' Complete' : 'Validate'}
                                                        </Stack>

                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            sx={{
                                                backgroundColor: "#fff",
                                                display: "flex",
                                            }}
                                        >
                                            <Grid
                                                container
                                                spacing={3}
                                            >
                                                {floorValidateData && !!floorValidateData?.summary?.uiErrors && floorValidateData?.status !== 'error' && (
                                                    <ErrorSummary
                                                        validateData={floorValidateData}
                                                        downloadURI={downloadURI}
                                                    />
                                                )}
                                                {floorValidateData && !!!floorValidateData?.summary?.uiErrors && floorValidateData?.status !== 'error' && (
                                                    <SuccessSummary
                                                        submitSuccess={submitSuccess}
                                                        submitSuccessData={submitSuccessData[floorItem.floorId]}
                                                        downloadURI={downloadURI}
                                                        validateData={floorValidateData}
                                                    />
                                                )}
                                                <ActiveWarehouseFiles
                                                    activeWarehouseFiles={activeWarehouseFiles[floorItem.floorId]}
                                                    downloadURI={downloadURI}
                                                    uploadFile={uploadFile}
                                                    validateLoading={validateLoading}
                                                    setMessage={setMessage}
                                                    navigateToViewFile={navigateToViewFile}
                                                />
                                            </Grid>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    <Stack direction="row" justifyContent="flex-end" sx={{ paddingRight: 2, mt: 1, mb: 5 }}>
                        <Button
                            sx={{
                                fontWeight: 400, height: 30, fontSize: 12, mt: 1, minWidth: 130, boxShadow: 0, "&:hover": {
                                    boxShadow: 0
                                }
                            }}
                            color="primary"
                            variant="contained"
                            disabled={isFilesUploaded}
                            onClick={() => {
                                setFloorsInfo(prevArr => [...prevArr, { description: `Floor ${floorsInfo.length + 1}`, floorId: `floor_${floorsInfo.length + 1}`, isEdit: false }])
                            }}
                            startIcon={<AddIcon size={12} />}
                        >
                            Add Floor
                        </Button>
                    </Stack>
                </Box >
            )
            }
            {
                loading &&
                <Box sx={{ display: 'flex' }} style={{ marginLeft: '45%', marginTop: '10%' }}>
                    <CircularProgress size={`100px`} />
                </Box>
            }
            <Modal
                open={visible}
                onClose={() => {
                    setVisible(false)
                }}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{
                    backgroundColor: '#fff',
                    width: 350,
                    padding: 2

                }}>
                    <Typography sx={{
                        textAlign: 'center', fontWeight: 600, color: '#5C5C5C'
                    }} variant="h5" component="h2">
                        Confirmation
                    </Typography>
                    <Typography sx={{ textAlign: 'center', fontSize: 13, mt: 1 }}>
                        Are you sure you want to save ?
                    </Typography>
                    <Stack flexDirection='row' justifyContent='center' sx={{ mt: 2, columnGap: 5 }}>
                        <Button sx={{
                            boxShadow: 0, "&:hover": {
                                boxShadow: 0
                            }
                        }} color='lightGrey' variant="contained" onClick={() => {
                            setVisible(false)
                        }}>
                            No
                        </Button>
                        <Button sx={{
                            boxShadow: 0, "&:hover": {
                                boxShadow: 0
                            }
                        }} color='blue' variant="contained" onClick={() => {
                            handleFloorInfoSubmit(submitIndex)
                        }}>
                            Ok
                        </Button>
                    </Stack>
                </Box>

            </Modal>
        </>
    );
}
export default Welcome;